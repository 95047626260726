<template>
  <div class="page-products__products">
    <v-row no-gutters class="elevation-2 mt-2 page__table-container">
      <HeaderTableTabs :currentTab.sync="currentTab" :tabs="tabs" />

      <v-col cols="12">
        <Resource
          :document="document"
          :filter-fields="null"
          :filter-data="filterData"
          :filter-callback="setFilterData"
          :searchable="searchableField"
          :columns="columns"
          :update-router-callback="getUpdateRouterData"
          :actionItems="items"
          :callback-action="getAction"
        />
      </v-col>
      <v-col cols="12" class="d-flex justify-space-between">
        <limit-page :callback="setLimit" />
        <pagination
          :length="lengthPage"
          :visible="0"
          v-if="!$parent.isLoading && document"
          :page.sync="page"
        ></pagination>
      </v-col>
    </v-row>
    <v-dialog persistent v-model="showModalDelete" width="500" style="transform-origin: center top">
      <v-card relative>
        <!-- Title -->
        <v-card-title class="headline"> {{ 'Delete' }} {{ ids.length }} offer?. </v-card-title>
        <div class="close-icon" @click="showModalDelete = false">
          <v-icon>fas fa-times</v-icon>
        </div>
        <v-divider class="mb-3"></v-divider>
        <v-card-text class="">
          Are you sure you want to delete {{ ids.length }} offers. This action cannot be reversed.
        </v-card-text>

        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="" @click="showModalDelete = false"> Cancel </v-btn>
          <v-btn :loading="isLoadingButton" color="error" @click="onSubmitDelete"> Delete </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Resource from '@/components/Resources';
import resourceListMixin from '@/mixins/resource-list';
import { offerApi } from '@/apis/offers';

export default {
  components: { Resource },
  mixins: [resourceListMixin],
  data() {
    return {
      showModalDelete: false,
      items: [
        { title: 'Active offer', action: 'activate' },
        { title: 'Deactivate offer ', action: 'deactivate' },
        { title: 'Delete ', action: 'remove-bulk' },
      ],
      resourceType: 'offer',
      // page: 1,
      tabs: [{ name: 'All offer', key: 'handpicked' }],
      testVuex: null,
      columns: {
        Selectable: {
          blankLabel: true,
          kind: 'selectable',
          width: 1,
        },

        ID: {
          field: '_id',
          kind: 'view',
        },
        'Target Product/Collection': {
          field: 'vendor',
          kind: 'showHandpickedTarget',
        },
        'Recommended product': {
          field: 'vendor',
          kind: 'showOffer',
        },
        Status: {
          kind: 'status',
          field: 'activated',
        },
      },
      updateRouteName: 'website.apps.widgets.handpicked.update',
      searchableField: 'title_search',
      sortable: [],
      ids: [],
      isLoadingButton: false,
    };
  },
  created() {},
  methods: {
    getIds(ids) {
      // this.$parent.ids = ids;
    },
    async onSubmitDelete() {
      try {
        this.isLoadingButton = true;
        await offerApi.remove(`?ids=${this.ids.join(',')}`);
        this.isLoading = true;
        this.showModalDelete = false;

        await this.getResources();
        this.isLoading = false;
      } catch (error) {
        //empty
      }
      this.showModalDelete = false;
      this.isLoadingButton = false;
    },
    async getAction(action, ids) {
      this.ids = ids;
      this.action = action.action;
      if (action.action === 'bulk-edit') {
        this.$router.push({
          name: 'website.products.bulkEdit',
          query: {
            ids: ids.join(','),
          },
        });
      }
      if (action.action === 'deactivate' || action.action === 'activate') {
        try {
          await offerApi.changeActive(action.action, ids);
          this.isLoading = true;

          await this.getResources();
          this.isLoading = false;
        } catch (error) {
          console.log(error);
          //empty
        }
      }

      if (action.action === 'remove-bulk') {
        this.showModalDelete = true;
      }
    },
  },
};
</script>
<style lang="scss">
.page-products__products {
  background: white;
}
</style>
