<template>
  <div class="product-page">
    <!-- website.apps.widgets.list -->
    <v-row no-gutters>
      <v-col cols="12" class="page-header d-flex py-3 align-center">
        <redirect class="mb-5" title="Handpicked" route="website.apps.widgets.list" />

        <!-- <span class="page-title">{{ $t('Handpicked products just for you') }}</span> -->
        <v-spacer></v-spacer>
        <!-- <v-col class="d-flex align-center"> -->
        <v-spacer></v-spacer>
        <v-btn
          class="mr-2"
          @click="
            $router.push({
              name: 'website.apps.widgets.handpicked.design',
              params: {
                widgetType: 'handpickedProduct',
              },
            })
          "
          outlined
          color="primary"
          ><i class="mr-1 fas fa-file-export"></i>{{ $t('Design') }}
        </v-btn>
        <v-btn color="primary" @click="$router.push({ name: 'website.apps.widgets.handpicked.create' })"
          ><i class="fas fa-plus mr-1"></i>{{ $t('Create new') }}</v-btn
        >
      </v-col>
      <v-col cols="12" class="page-header d-flex py-3 align-center">
        <span class="page-title">{{ $t('Handpicked products') }}</span>
      </v-col>
      <!-- </v-col> -->
    </v-row>
    <Offer />
  </div>
</template>

<script>
import Offer from './components/Offer';
export default {
  components: {
    Offer,
  },
};
</script>
